.info-hotspot-modal {
  font-family: "Museo sans", sans-serif;
  font-weight: 300;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.2s ease-in-out 0.5s, visibility 0s 0.7s;

  &.info-hotspot-modal:not(.singlePaneModal):not(.pdf) {
    padding: 5% 10%;
  }

  &.singlePaneModal {
    & > .info-hotspot-header {
      width: auto;
      .info-hotspot-title {
        display: none;
      }
    }
    .singlePaneContent {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      height: auto;
      &.singlePaneVideo {
        width: 100%;
        &::before {
          content: "LOADING ...";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          z-index: -1;
          display: flex;
        }
        > .singlePaneContentInner {
          width: 100%;
          & > div > iframe {
            border-radius: 1rem;
          }
        }
      }
      & > .singlePaneContentInner {
        display: flex;
        padding: 2rem;
        background: transparent;
        & > img {
          border-radius: 1rem;
          object-fit: contain;
          display: flex;
          width: 100%;
        }
        & > a {
          position: absolute;
          right: 1rem;
          top: 1rem;
        }
        & > .info-hotspot-content--close {
          background-color: #292829;
          color: var(--secondary-white);
          width: 3rem;
          height: 3rem;
          font-size: 0.875rem;
          font-weight: 500;
          border-radius: 100%;
          z-index: 4;
          line-height: 3rem;
          text-align: center;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
  &.nobg {
    background: none;
  }

  &.withbg {
    background-color: rgba(0, 0, 0, 0.89);
  }

  .info-hotspot-header {
    width: 100%;
    max-width: 1050px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0.2s;
    position: relative;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s ease-in-out, visibility 0s 0s;
  }

  &.visible .info-hotspot-header {
    opacity: 1;
    transition: opacity 0.1s ease-in-out 0.2s;
  }

  .info-hotspot-icon {
    display: none;
  }

  .info-hotspot-title-wrapper {
    padding-bottom: 0.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .info-hotspot-title {
      display: inline-block;
      vertical-align: middle;
      font-family: "Antonio";
      font-size: 1.25em;
      text-transform: uppercase;
      user-select: text;
    }
  }

  .info-hotspot-close-wrapper {
    display: flex;
    max-width: 100%;
    max-height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: -2rem;
    bottom: 0.25rem;
    & > img {
      width: 20px;
      filter: invert(1);
    }
  }

  &.visible .info-hotspot-content {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-in-out 0.4s;
    -o-transition: opacity 0.3s ease-in-out 0.4s;
    transition: opacity 0.3s ease-in-out 0.4s;
  }

  .info-hotspot-content {
    width: 100%;
    height: 600px;
    max-width: 1000px;
    border-radius: 1rem;
    display: flex;
    overflow: hidden;
    background-color: #fff;
    color: #282727;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    user-select: text;
    position: relative;

    .carousel_container,
    .product-txt-container {
      width: 50%;
      height: 100%;
      overflow-x: hidden;
    }
  }
}

.fullModal {
  &.pdf {
    padding: unset;
    padding: 10% 5%;
    overflow: hidden;
  }
  margin: auto;

  .fullContent {
    display: flex;
    min-height: 100%;
    background-color: transparent;

    &::before {
      content: "LOADING ...";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      z-index: -1;
      display: flex;
    }

    .iframeContainer {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      height: 100%;

      iframe {
        border: none;
        border-radius: 1rem;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.iframe_container,
.iframe_container__video {
  margin: 0 auto;
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: relative;
}

.iframe_container__video {
  position: relative;
  overflow: hidden;
  width: 100%;
  background: #fff;
}

.iframe_container__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.carousel_container {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;

    .carousel-images {
      display: flex;
      transform: translateX(0);
      transform-style: preserve-3d;
      transition: transform 0.3s ease-in-out;
      min-width: 100%;
      height: 100%;
      user-select: none;
    }

    #product-image {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;
      height: 100%;

      #product-image:not(.iframe_container__video) {
        padding: 0.15em;
      }

      & > * {
        pointer-events: none;
        object-fit: contain;
        min-width: 100%;
        height: 100%;
      }
    }
  }

  .carousel-nav {
    display: flex;
    align-self: center;
    width: 100%;
    max-height: fit-content;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: absolute;
    pointer-events: none;
    .carousel-button {
      cursor: pointer;
      background: var(--secondary-white);
      border-radius: 50%;
      opacity: 1;
      z-index: 1;
      pointer-events: all;
      max-width: 25px;
      max-height: 25px;
      margin: auto 5px;
      border: 0.02em solid var(--accent-gray);
      &.previous {
        -webkit-box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
        box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.5);
      }

      &.next {
        -webkit-box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.5);
        box-shadow: -1px 2px 2px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .carousel-pagination {
    padding-inline-start: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 2%;
    min-width: 50%;
    list-style: none;
    margin: 0 auto;
    text-align: center;
    z-index: 10000000;
  }

  .carousel-bullet {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: var(--secondary-blk-opaque);
    cursor: pointer;
    margin: 0 5px;
    border-radius: 50%;
    opacity: 0.8;
    transition-property: opacity, background-color, -webkit-transform;
    transition-property: transform, opacity, background-color;
    transition-property: transform, opacity, background-color, -webkit-transform;
    transition-duration: 0.3s;
  }

  .carousel-bullet:hover {
    transform: scale(1.3);
  }

  .carousel-bullet.active-bullet,
  .carousel-bullet.active-bullet:hover {
    transform: scale(1.3);
    /* background-color: var(--secondary-white); */
    opacity: 0.35;
    cursor: default;
  }
}

/* left side modal styles */
.product-txt-container {
  width: 50%;
  overflow-y: auto;
  margin: 0 auto;
  display: grid;
  align-items: center;
  justify-content: space-evenly;

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--secondary-blk);
  }

  .product-txt-wrapper {
    padding: 0 1rem 0 1.5rem;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    padding: 7.5%;

    .product-txt-subhead {
      max-width: 24ch;
      text-transform: uppercase;
      font-family: "Antonio";
      font-size: 2rem;
      font-weight: 800;
      align-self: start;
      padding-bottom: 0;
      margin: 0;
    }
  }
}

.product-txt {
  font-size: clamp(0.7rem, 10vw, 1rem);
  padding-bottom: 1.5rem;

  .no-bullet {
    list-style: none !important;
    margin-bottom: 0 !important;
    text-decoration: underline 0.0005rem;
  }

  span.b {
    font-weight: 500;
    .no-padtop {
      position: relative;
      top: 1rem;
    }
  }

  .teal-text {
    color: var(--primary-blue);
    font-weight: bold;
  }

  .em {
    font-style: italic;
  }

  .three-col {
    list-style: none;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 500;
    margin-top: 1em;
    margin-left: -1rem;
    margin-bottom: 0.75em;

    .b-italic {
      font-family: "Antonio", sans-serif;
      letter-spacing: 0.03rem;
      line-height: 1em;
      color: var(--primary-blue);
      padding-top: 0.5rem;
      margin: 0 auto;
      padding-left: 1em;
    }

    .no-line-any {
      display: flex;
      width: 100%;
      font-weight: 500;
      font-family: "Antonio", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-size: 1.35em;
      line-height: 1.12em;
    }

    .line {
      display: flex;
      width: 80%;
      height: 1px;
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      background: var(--secondary-blk);
    }
  }

  ul {
    padding-inline: 1.5em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    margin: auto;

    &:not(&.one-prod) {
      padding-top: 1.2em;
    }

    &.no-padtop {
      padding-top: 0;
    }

    .title {
      align-items: center;
      flex-wrap: nowrap;
      justify-content: space-between;
      font-family: "Antonio", sans-serif;
      letter-spacing: 0.032em;
      font-size: 1.5em;
      line-height: 1.2em;
      text-transform: uppercase;
      margin-top: 1em;
      margin-left: -1rem;
      margin-bottom: 0.5em;
      width: 100%;

      // &:first-of-type:not(&.pad-up) {
      //   margin-top: 0;
      // }

      &.noblock {
        display: inline-block;
      }

      &:not(&.no-line-any) {
        &:after {
          display: flex;
          content: "";
          width: 100%;
          height: 1px;
          margin-top: 0.5rem;
          background: var(--secondary-blk);
        }
      }

      &.no-line {
        display: flex;
        &:after {
          display: flex;
          content: "";
          width: 100%;
          height: 1px;
          margin-left: -1rem;
          margin-top: 0.4rem;
          background: var(--secondary-blk);
        }
      }
    }

    li {
      padding-top: 0.5rem;
    }

    .sup {
      font-size: 0.5em;
      font-weight: 500;
      vertical-align: 1em;
    }
  }
}

.recipes {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding-top: 1rem;
  border-top: 1px solid var(--secondary-blk);

  .recipes-header {
    font-family: "Antonio";
    text-transform: uppercase;
    font-size: 2rem;
    padding: 0.5rem 0 2rem 0;
  }

  .recipes-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: baseline;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    gap: 1rem;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: all 0.25s ease;
      align-items: center;

      .recipe-link {
        &:hover {
          cursor: pointer;
        }

        .recipe-preview-img-wrap {
          transition: all 0.2s ease-in-out;
          width: 100%;
          height: auto;
          border-radius: 1rem;
          &:hover {
            box-shadow: 0 10px 20px 0 rgba(146, 146, 146, 0.2), 0 -10px 20px 0 rgb(182, 182, 182, 0.25);
            transform: scale(1.05);
          }
        }

        .recipe-title {
          padding-top: 1.2rem;
          display: flex;
          font-size: 0.75rem;
          align-items: center;
          justify-content: center;
          font-weight: 300;
          font-style: italic;
          transition: all 0.1s ease-in-out;
          width: 100%;
          height: 100%;
          max-width: 150px;
          margin: 0 auto;
          text-align: center;
          font-weight: 500;
        }
      }
    }
  }
}

.__related-content {
  width: 140px;
  height: 80px;
}

.desktop .info-hotspot .info-hotspot-header:hover {
  width: 120px;
  z-index: 10;
}

#pano {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 100vh;
}

#pano-arrows {
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
  grid-template-rows: 1fr 7fr 1fr;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 11;
}

.arrow {
  width: 50px;
  height: 50px;
  pointer-events: auto;
  justify-self: center;
  align-self: center;
  border: none;
  transition: opacity 0.3s ease-in-out;
}

.arrow-up {
  grid-row: 1;
  grid-column: 2;
}

.arrow-down {
  grid-row: 3;
  grid-column: 2;
  transform: rotate(180deg); /* rotate the arrow 180 degrees */
}

.arrow-left {
  grid-row: 2;
  grid-column: 1;
  transform: rotate(-90deg); /* rotate the arrow -90 degrees */
}

.arrow-right {
  grid-row: 2;
  grid-column: 3;
  transform: rotate(90deg); /* rotate the arrow 90 degrees */
}

// .toggleButton {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   z-index: 150;
// }

// .toggleButtonUS {
//   background-color: #0A3161;
// }

// .toggleButtonCA {
//   background-color: #D80621;
// }

.toggleContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 150;
  user-select: none;
  display: flex;
  gap: 5px;

  span {
    display: inline-block;
    font-size: large;
    color: white;
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
}

.toggle {
  position: relative;
  z-index: 150;
  --width: 40px; /* Double the width */
  --height: calc(var(--width) / 2);
  --border-radius: calc(var(--height) / 2); /* Adjust the border-radius accordingly */

  display: inline-block;
  cursor: pointer;
}

.toggle__input {
  display: none;
}

.toggle__fill {
  display: inline-block;
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: #462ae6;
  transition: background-color 0.3s;
}

.toggle__input:checked ~ .toggle__fill {
  background-color: #eb5135;
}

.toggle__fill::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: var(--height);
  width: var(--height);
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
  transition: transform 0.2s;
}

.toggle__input:checked ~ .toggle__fill::after {
  transform: translateX(var(--height));
}

.toggleSpanHidden {
  display: none;
}
