.info-hotspot-modal {
  z-index: 15;
  opacity: 0;
  visibility: hidden;
  background-color: #000000b3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: Museo sans, sans-serif;
  font-weight: 300;
  transition: all .2s ease-in-out .5s, visibility 0s .7s;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.info-hotspot-modal.info-hotspot-modal:not(.singlePaneModal):not(.pdf) {
  padding: 5% 10%;
}

.info-hotspot-modal.singlePaneModal > .info-hotspot-header {
  width: auto;
}

.info-hotspot-modal.singlePaneModal > .info-hotspot-header .info-hotspot-title {
  display: none;
}

.info-hotspot-modal.singlePaneModal .singlePaneContent {
  background: none;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  display: flex;
}

.info-hotspot-modal.singlePaneModal .singlePaneContent.singlePaneVideo {
  width: 100%;
}

.info-hotspot-modal.singlePaneModal .singlePaneContent.singlePaneVideo:before {
  content: "LOADING ...";
  color: #fff;
  z-index: -1;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info-hotspot-modal.singlePaneModal .singlePaneContent.singlePaneVideo > .singlePaneContentInner {
  width: 100%;
}

.info-hotspot-modal.singlePaneModal .singlePaneContent.singlePaneVideo > .singlePaneContentInner > div > iframe {
  border-radius: 1rem;
}

.info-hotspot-modal.singlePaneModal .singlePaneContent > .singlePaneContentInner {
  background: none;
  padding: 2rem;
  display: flex;
}

.info-hotspot-modal.singlePaneModal .singlePaneContent > .singlePaneContentInner > img {
  -o-object-fit: contain;
  object-fit: contain;
  border-radius: 1rem;
  width: 100%;
  display: flex;
}

.info-hotspot-modal.singlePaneModal .singlePaneContent > .singlePaneContentInner > a {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.info-hotspot-modal.singlePaneModal .singlePaneContent > .singlePaneContentInner > .info-hotspot-content--close {
  color: var(--secondary-white);
  z-index: 4;
  text-align: center;
  cursor: pointer;
  background-color: #292829;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  font-size: .875rem;
  font-weight: 500;
  line-height: 3rem;
  text-decoration: none;
}

.info-hotspot-modal.nobg {
  background: none;
}

.info-hotspot-modal.withbg {
  background-color: #000000e3;
}

.info-hotspot-modal .info-hotspot-header {
  opacity: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1050px;
  transition: opacity .3s ease-in-out .2s;
  display: flex;
  position: relative;
}

.info-hotspot-modal.visible {
  opacity: 1;
  visibility: visible;
  transition: opacity .1s ease-in-out, visibility;
}

.info-hotspot-modal.visible .info-hotspot-header {
  opacity: 1;
  transition: opacity .1s ease-in-out .2s;
}

.info-hotspot-modal .info-hotspot-icon {
  display: none;
}

.info-hotspot-modal .info-hotspot-title-wrapper {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: .5rem;
  display: flex;
}

.info-hotspot-modal .info-hotspot-title-wrapper .info-hotspot-title {
  vertical-align: middle;
  text-transform: uppercase;
  -webkit-user-select: text;
  user-select: text;
  font-family: Antonio;
  font-size: 1.25em;
  display: inline-block;
}

.info-hotspot-modal .info-hotspot-close-wrapper {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  position: absolute;
  bottom: .25rem;
  right: -2rem;
}

.info-hotspot-modal .info-hotspot-close-wrapper > img {
  filter: invert();
  width: 20px;
}

.info-hotspot-modal.visible .info-hotspot-content {
  opacity: 1;
  transition: opacity .3s ease-in-out .4s;
}

.info-hotspot-modal .info-hotspot-content {
  color: #282727;
  opacity: 0;
  -webkit-user-select: text;
  user-select: text;
  background-color: #fff;
  border-radius: 1rem;
  width: 100%;
  max-width: 1000px;
  height: 600px;
  transition: opacity .3s ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.info-hotspot-modal .info-hotspot-content .carousel_container, .info-hotspot-modal .info-hotspot-content .product-txt-container {
  width: 50%;
  height: 100%;
  overflow-x: hidden;
}

.fullModal {
  margin: auto;
}

.fullModal.pdf {
  padding: unset;
  padding: 10% 5%;
  overflow: hidden;
}

.fullModal .fullContent {
  background-color: #0000;
  min-height: 100%;
  display: flex;
}

.fullModal .fullContent:before {
  content: "LOADING ...";
  color: #fff;
  z-index: -1;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fullModal .fullContent .iframeContainer {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.fullModal .fullContent .iframeContainer iframe {
  border: none;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
}

.iframe_container, .iframe_container__video {
  background: none;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.iframe_container__video {
  background: #fff;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.iframe_container__video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.carousel_container {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.carousel_container .carousel {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.carousel_container .carousel .carousel-images {
  transform-style: preserve-3d;
  -webkit-user-select: none;
  user-select: none;
  min-width: 100%;
  height: 100%;
  transition: transform .3s ease-in-out;
  display: flex;
  transform: translateX(0);
}

.carousel_container .carousel #product-image {
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 100%;
  display: flex;
}

.carousel_container .carousel #product-image #product-image:not(.iframe_container__video) {
  padding: .15em;
}

.carousel_container .carousel #product-image > * {
  pointer-events: none;
  -o-object-fit: contain;
  object-fit: contain;
  min-width: 100%;
  height: 100%;
}

.carousel_container .carousel-nav {
  pointer-events: none;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  max-height: fit-content;
  display: flex;
  position: absolute;
}

.carousel_container .carousel-nav .carousel-button {
  cursor: pointer;
  background: var(--secondary-white);
  opacity: 1;
  z-index: 1;
  pointer-events: all;
  border: .02em solid var(--accent-gray);
  border-radius: 50%;
  max-width: 25px;
  max-height: 25px;
  margin: auto 5px;
}

.carousel_container .carousel-nav .carousel-button.previous {
  box-shadow: 1px 2px 2px #00000080;
}

.carousel_container .carousel-nav .carousel-button.next {
  box-shadow: -1px 2px 2px #00000080;
}

.carousel_container .carousel-pagination {
  text-align: center;
  z-index: 10000000;
  justify-content: center;
  align-items: center;
  min-width: 50%;
  margin: 0 auto;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 2%;
  padding-inline-start: 0 !important;
}

.carousel_container .carousel-bullet {
  background-color: var(--secondary-blk-opaque);
  cursor: pointer;
  opacity: .8;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 0 5px;
  transition-property: transform, opacity, background-color;
  transition-duration: .3s;
  display: inline-block;
}

.carousel_container .carousel-bullet:hover {
  transform: scale(1.3);
}

.carousel_container .carousel-bullet.active-bullet, .carousel_container .carousel-bullet.active-bullet:hover {
  opacity: .35;
  cursor: default;
  transform: scale(1.3);
}

.product-txt-container {
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  display: grid;
  overflow-y: auto;
}

.product-txt-container::-webkit-scrollbar-track {
  background-color: #fff;
}

.product-txt-container::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
}

.product-txt-container::-webkit-scrollbar-thumb {
  background-color: var(--secondary-blk);
}

.product-txt-container .product-txt-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: max-content;
  margin: 0;
  padding: 7.5%;
  display: flex;
}

.product-txt-container .product-txt-wrapper .product-txt-subhead {
  text-transform: uppercase;
  align-self: start;
  max-width: 24ch;
  margin: 0;
  padding-bottom: 0;
  font-family: Antonio;
  font-size: 2rem;
  font-weight: 800;
}

.product-txt {
  padding-bottom: 1.5rem;
  font-size: clamp(.7rem, 10vw, 1rem);
}

.product-txt .no-bullet {
  -webkit-text-decoration: underline .0005rem;
  text-decoration: underline .0005rem;
  margin-bottom: 0 !important;
  list-style: none !important;
}

.product-txt span.b {
  font-weight: 500;
}

.product-txt span.b .no-padtop {
  position: relative;
  top: 1rem;
}

.product-txt .teal-text {
  color: var(--primary-blue);
  font-weight: bold;
}

.product-txt .em {
  font-style: italic;
}

.product-txt .three-col {
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1em;
  margin-bottom: .75em;
  margin-left: -1rem;
  font-weight: 500;
  list-style: none;
  display: flex;
}

.product-txt .three-col .b-italic {
  letter-spacing: .03rem;
  color: var(--primary-blue);
  margin: 0 auto;
  padding-top: .5rem;
  padding-left: 1em;
  font-family: Antonio, sans-serif;
  line-height: 1em;
}

.product-txt .three-col .no-line-any {
  text-transform: uppercase;
  letter-spacing: .1em;
  width: 100%;
  font-family: Antonio, sans-serif;
  font-size: 1.35em;
  font-weight: 500;
  line-height: 1.12em;
  display: flex;
}

.product-txt .three-col .line {
  background: var(--secondary-blk);
  width: 80%;
  height: 1px;
  margin-top: .5rem;
  margin-left: .5rem;
  display: flex;
}

.product-txt ul {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  margin: auto;
  padding-inline: 1.5em;
  display: flex;
}

.product-txt ul:not(.product-txt ul.one-prod) {
  padding-top: 1.2em;
}

.product-txt ul.no-padtop {
  padding-top: 0;
}

.product-txt ul .title {
  letter-spacing: .032em;
  text-transform: uppercase;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1em;
  margin-bottom: .5em;
  margin-left: -1rem;
  font-family: Antonio, sans-serif;
  font-size: 1.5em;
  line-height: 1.2em;
}

.product-txt ul .title.noblock {
  display: inline-block;
}

.product-txt ul .title:not(.product-txt ul .title.no-line-any):after {
  content: "";
  background: var(--secondary-blk);
  width: 100%;
  height: 1px;
  margin-top: .5rem;
  display: flex;
}

.product-txt ul .title.no-line {
  display: flex;
}

.product-txt ul .title.no-line:after {
  content: "";
  background: var(--secondary-blk);
  width: 100%;
  height: 1px;
  margin-top: .4rem;
  margin-left: -1rem;
  display: flex;
}

.product-txt ul li {
  padding-top: .5rem;
}

.product-txt ul .sup {
  vertical-align: 1em;
  font-size: .5em;
  font-weight: 500;
}

.recipes {
  border-top: 1px solid var(--secondary-blk);
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 0;
  padding-top: 1rem;
  display: flex;
}

.recipes .recipes-header {
  text-transform: uppercase;
  padding: .5rem 0 2rem;
  font-family: Antonio;
  font-size: 2rem;
}

.recipes .recipes-list {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: baseline;
  gap: 1rem;
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
}

.recipes .recipes-list div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .25s;
  display: flex;
}

.recipes .recipes-list div .recipe-link:hover {
  cursor: pointer;
}

.recipes .recipes-list div .recipe-link .recipe-preview-img-wrap {
  border-radius: 1rem;
  width: 100%;
  height: auto;
  transition: all .2s ease-in-out;
}

.recipes .recipes-list div .recipe-link .recipe-preview-img-wrap:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px #92929233, 0 -10px 20px #b6b6b640;
}

.recipes .recipes-list div .recipe-link .recipe-title {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 150px;
  height: 100%;
  margin: 0 auto;
  padding-top: 1.2rem;
  font-size: .75rem;
  font-style: italic;
  font-weight: 500;
  transition: all .1s ease-in-out;
  display: flex;
}

.__related-content {
  width: 140px;
  height: 80px;
}

.desktop .info-hotspot .info-hotspot-header:hover {
  z-index: 10;
  width: 120px;
}

#pano {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
  display: grid;
}

#pano-arrows {
  pointer-events: none;
  z-index: 11;
  grid-template-rows: 1fr 7fr 1fr;
  grid-template-columns: 1fr 7fr 1fr;
  display: grid;
  position: absolute;
  inset: 0;
}

.arrow {
  pointer-events: auto;
  border: none;
  place-self: center;
  width: 50px;
  height: 50px;
  transition: opacity .3s ease-in-out;
}

.arrow-up {
  grid-area: 1 / 2;
}

.arrow-down {
  grid-area: 3 / 2;
  transform: rotate(180deg);
}

.arrow-left {
  grid-area: 2 / 1;
  transform: rotate(-90deg);
}

.arrow-right {
  grid-area: 2 / 3;
  transform: rotate(90deg);
}

.toggleContainer {
  z-index: 150;
  -webkit-user-select: none;
  user-select: none;
  gap: 5px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.toggleContainer span {
  color: #fff;
  flex-grow: 1;
  justify-content: center;
  font-size: large;
  display: flex;
}

.toggle {
  z-index: 150;
  --width: 40px;
  --height: calc(var(--width) / 2);
  --border-radius: calc(var(--height) / 2);
  cursor: pointer;
  display: inline-block;
  position: relative;
}

.toggle__input {
  display: none;
}

.toggle__fill {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: #462ae6;
  transition: background-color .3s;
  display: inline-block;
}

.toggle__input:checked ~ .toggle__fill {
  background-color: #eb5135;
}

.toggle__fill:after {
  content: "";
  height: var(--height);
  width: var(--height);
  border-radius: var(--border-radius);
  background: #fff;
  transition: transform .2s;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 10px #00000040;
}

.toggle__input:checked ~ .toggle__fill:after {
  transform: translateX(var(--height));
}

.toggleSpanHidden {
  display: none;
}
/*# sourceMappingURL=index.f5204029.css.map */
